.rowsContainer{
    @apply capitalize;
}

.rowsContainer > div{
    @apply py-2 px-3 grid grid-cols-1 md:grid-cols-2 gap-4;
}
.rowsContainer > div :nth-child(1){
    @apply md:ms-auto;
}
.rowsContainer > div :nth-child(2){
    @apply md:me-auto font-bold;
}
.rowsContainer > :nth-child(odd){
    @apply bg-pink-500 text-white;
}